import { CaretDownOutlined } from '@ant-design/icons';
import { Tag, type MenuProps } from 'antd';
import { NavLink, Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Shirts from '../pages/Shirts';
import GeneAssist from '../pages/GeneAssist';
import Blue from '../pages/Blue';
import RxAlert from '../pages/RxAlert';
import JotSpotiOS from '../pages/JotSpotiOS';
import JotSpotmacOS from '../pages/JostSpotmacOS';
import Electrified from '../pages/Electrified';
import Contact from '../pages/Contact';
import Error from '../pages/Error';
import ModelsPage from '../pages/ModelsPage';
import ModelsArticle from '../fragments/ModelsArticle';
import CMS from '../internal/cms/CMS';
import WishListLanding from '../internal/wishList/WishListLanding';
import EditorPage from '../internal/cms/EditorPage';
import Brian from '../pages/Brian';
import Pinwheel from '../pages/Pinwheel';
import EngineSwap from '../pages/EngineSwap';

export const PageRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Error />} />
      <Route path="/" element={<Home />} />
      <Route path="/models" element={<ModelsPage />} />
      <Route path="/models/:slug" element={<ModelsArticle />} />
      <Route path="/shirts" element={<Shirts />} />
      <Route path="/gene" element={<GeneAssist />} />
      <Route path="/blue" element={<Blue />} />
      <Route path="/rx" element={<RxAlert />} />
      <Route path="/jotspot" element={<JotSpotiOS />} />
      <Route path="/brian" element={<Brian />} />
      <Route path='/pinwheel' element={<Pinwheel />} />
      <Route path="/jotspot-mac" element={<JotSpotmacOS />} />
      <Route path='/engine-swap' element={<EngineSwap />} />
      <Route path="/electrified" element={<Electrified />} />
      <Route path="/wishlist" element={<WishListLanding />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/cms" element={<CMS />} />
      <Route path="/cms/editor/:editorType/:id?" element={<EditorPage />} />
    </Routes>
  );
};

export const NavigationLinks: MenuProps['items'] = [
  {
    label: <NavLink to="/">💻 ✈️ 🎬</NavLink>,
    key: 'home',
  },
  {
    label: <NavLink to="/models">Model Making</NavLink>,
    key: 'models',
  },
  {
    label: <NavLink to="/shirts">Shirts</NavLink>,
    key: 'shirts',
  },
  {
    label: 'Software',
    key: 'software',
    icon: <CaretDownOutlined />,
    expandIcon: <></>,
    children: [
      {
        label: <NavLink to='/engine-swap'>Engine Swap</NavLink>,
        key: 'engine-swap',
      },
      {
        label: (
          <NavLink to="/pinwheel">
            Pinwheel<Tag color="blue" style={{ marginLeft: '10px' }}>Beta</Tag>
          </NavLink>
        ),
        key: 'pinwheel',
      },
      {
        label: (
          <NavLink to="/brian">
            Brian<Tag color="blue" style={{ marginLeft: '10px' }}>Beta</Tag>
          </NavLink>
        ),
        key: 'brian',
      },
      {
        label: <NavLink to="/gene">Gene Assist</NavLink>,
        key: 'gene',
      },
      {
        label: <NavLink to="/blue">Blue</NavLink>,
        key: 'blue',
      },
      {
        label: <NavLink to="/rx">Rx Alert</NavLink>,
        key: 'rx',
      },
      {
        label: <NavLink to="/jotspot">JotSpot iOS</NavLink>,
        key: 'jotspot',
      },
      {
        label: <NavLink to="/jotspot-mac">JotSpot macOS</NavLink>,
        key: 'jotspot-mac',
      },
    ],
  },
  {
    label: 'Movies',
    key: 'movies',
    icon: <CaretDownOutlined />,
    expandIcon: <></>,
    children: [
      {
        label: <NavLink to="/electrified">Electrified</NavLink>,
        key: 'electrified',
      },
    ],
  },
  {
    label: <NavLink to="/contact">Contact</NavLink>,
    key: 'contact',
  },
];

/**
 * Converts a page path name into a formatted title
 * to be used in the browser tab
 * @param page the name of the page path
 * @returns The properly formatted title of the page
 */
export const mapPageToName = (page: string): string => {
  switch (page) {
    case 'models':
      return 'Model Making';
    case 'shirts':
      return 'Shirts';
    case 'gene':
      return 'Gene Assist';
    case 'blue':
      return 'Blue';
    case 'rx':
      return 'Rx Alert';
    case 'jotspot':
      return 'JotSpot iOS';
    case 'jotspot-mac':
      return 'JotSpot macOS';
    case 'brian':
      return 'Brian';
    case 'pinwheel':
      return 'Pinwheel';
    case 'electrified':
      return 'Electrified';
    case 'contact':
      return 'Contact';
    default:
      return '';
  }
};
