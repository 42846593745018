import { ClockCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import MovieFragment from "../fragments/MovieFragment";

const Electrified = () => {
  return (
    <MovieFragment
      title={"Electrified"}
      subTitle={"The Current State of Electric Vehicles"}
      poster="./images/Poster Tall.jpg"
      stats={[
        {
          title: "Time",
          value: "37:05",
          icon: <ClockCircleOutlined />,
        },
        {
          title: "Release Date",
          value: "December 27, 2019",
          icon: <CalendarOutlined />,
        },
      ]}
      description="Chelsea Sexton, Paul Scott, Forth Mobility, and several owners come together to discuss how electric cars have evolved and where we currently are in the state of this developing technology. It covers issues and experiences related to charging, battery degradation, and range anxiety, along with detailed explanations of charging levels, types of electric vehicles, and the future of hydrogen. This film prepares viewers with the knowledge needed for properly assessing vehicle options, along with considerations for charging, and knowledge about what they may need to know before making a purchase."
      amazonLink="https://www.amazon.com/gp/video/detail/B083F63TPV/ref=cm_sw_em_r_pv_wb_98CUVQNpRUNew"
      youtubeLink="https://youtu.be/VJ1gxwg6FrI"
      award="2020 Drive Electric Student Award Winner"
    />
  );
};

export default Electrified;
