import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCGYZroycMVI5H9NW5MZ7n3j7JTPMDVBBg",
  authDomain: "ryan-hunter-331cf.firebaseapp.com",
  projectId: "ryan-hunter-331cf",
  storageBucket: "ryan-hunter-331cf.appspot.com",
  messagingSenderId: "79069397078",
  appId: "1:79069397078:web:fd39a5ba256f4ca9673bf7",
  measurementId: "G-38EEBHJT9C",
};

const firebaseApp = initializeApp(firebaseConfig);

export const firestoreDB = getFirestore(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
const analytics = getAnalytics(firebaseApp);
