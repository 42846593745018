import { faGlobe, faKeyboard, faSearch } from "@fortawesome/free-solid-svg-icons";
import SoftwareFragment from "../fragments/SoftwareFragment"

const EngineSwap = () => {
    return (
        <SoftwareFragment
            appName="Engine Swap"
            appIcon="./images/EngineSwap/icon.png"
            appLink="https://chromewebstore.google.com/detail/engine-swap/mgeollbbgnijbnafhkgkgacdmebfncnb"
            subTitle="Search Engine Converter"
            description="Engine Swap is a search engine converter. It will automatically fill in your current search and allow you to perform that search on another engine with one click. It also allows you to quickly perform a search from anywhere just by typing your query into the field from any page."
            images={[
                "./images/EngineSwap/1.png",
                "./images/EngineSwap/2.png",
            ]}
            features={[
                {
                    title: "Auto Fill Text Field",
                    description: "Engine Swap will automatically fill in your current search and allow you to perform that search on another engine with one click.",
                    icon: faKeyboard,
                },
                {
                    title: "Quick Search",
                    description: "Quickly perform a search from anywhere just by typing your query into the field from any page.",
                    icon: faGlobe
                },
                {
                    title: "Multiple Search Engines",
                    description: "Multiple search engine options are included, and more are coming soon",
                    icon: faSearch
                }
            ]}
            price="Free"
            supportedVersion="Chrome-Based Browsers"
            size="1.36 MB"
        />
    );
};

export default EngineSwap;