import { Divider, Skeleton } from "antd";

const LoadingSkeleton = () => {
  return (
    <div>
      <Skeleton.Image active />
      <Divider />
      <Skeleton active title paragraph={{ rows: 2 }} />
    </div>
  );
};

export default LoadingSkeleton;
