import { Badge, Button, Card, Col, Row, Typography, Image } from "antd";
import React, { FC } from "react";
import { ProductListing } from "../constants/Interfaces";
import LoadingSkeleton from "../components/LoadingSkeleton";
import { openURL } from "./Helpers";

interface Props {
  pageTitle: string;
  products: ProductListing[];
}

const ProductsPage: FC<Props> = ({ pageTitle, products }) => {
  const [loading, setLoading] = React.useState(true);

  return (
    <div>
      <Typography.Title>{pageTitle}</Typography.Title>
      <Row gutter={16}>
        {products.map((product) => (
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{ paddingBottom: "8px" }}
            key={product.title}
          >
            <div hidden={loading}>
              <Badge.Ribbon
                color={product.color}
                text={product.ribbon}
                style={{ visibility: product.ribbon ? "visible" : "hidden" }}
              >
                <Card
                  hoverable={!product.disabled}
                  cover={
                    <Image
                      src={product.image}
                      fallback="./images/blank.jpg"
                      preview={false}
                      onClick={() =>
                        product.disabled ? null : openURL(product.url)
                      }
                      onLoad={() => setLoading(false)}
                    />
                  }
                >
                  <Card.Meta
                    title={product.title}
                    description={
                      <Button
                        href={product.url}
                        target="_blank"
                        disabled={product.disabled}
                      >
                        Learn More
                      </Button>
                    }
                  />
                </Card>
              </Badge.Ribbon>
            </div>

            {loading && <LoadingSkeleton />}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ProductsPage;
