import { faCloud, faPills, faUsers } from "@fortawesome/free-solid-svg-icons";
import SoftwareFragment from "../fragments/SoftwareFragment";

const RxAlert = () => {
  return (
    <SoftwareFragment
      appName={"Rx Alert"}
      appIcon={"./images/RxAlert/icon.png"}
      appLink={"https://apps.apple.com/us/app/rx-alert/id1622253475"}
      subTitle={"Prescription Reminder And Tracker"}
      description={
        "Never forget to take your medication or prescriptions ever again. With Rx Alert, simply enter information about your medication and choose a time to get reminded. You can add multiple reminder times with a custom schedule for each day. Rx Alert supports multiple users and iCloud sync. Track medication for multiple people and sync changes across devices with ease.\nRx Alert allows you to include a wealth of information, including dosage, custom dose types, as well as pill shape, size, and color.\nWith Rx Alert, you can easily track medications, prescriptions, vitamins, eye drops, and more for everyone in the family."
      }
      images={[
        "./images/RxAlert/1.png",
        "./images/RxAlert/2.png",
        "./images/RxAlert/3.png",
        "./images/RxAlert/4.png",
        "./images/RxAlert/5.png",
        "./images/RxAlert/6.png",
      ]}
      features={[
        {
          title: "iCloud Sync",
          description:
            "Sync prescriptions, reminders, and edits between devices with iCloud",
          icon: faCloud,
        },
        {
          title: "Multiple Users",
          description:
            "Track medication for multiple users and get notifications for each person",
          icon: faUsers,
        },
        {
          title: "Custom Dose Types",
          description:
            "Track nearly anything with custom dose types and quantities",
          icon: faPills,
        },
      ]}
      price={"$0.99"}
      supportedVersion={"iOS 15+"}
      size={"5 MB"}
    />
  );
};

export default RxAlert;
