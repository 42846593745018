import { Button, Form, Input, InputNumber, Rate } from "antd";
import { addDoc, doc, updateDoc } from "firebase/firestore";
import { FC, useContext, useEffect, useState } from "react";
import { firestoreDB } from "../../firebaseConfig";
import { HeartOutlined } from "@ant-design/icons";
import { WishListProps } from "../../constants/Interfaces";
import { requiredRule } from "../../constants/FormRules";
import TagAdder, { TagSchema } from "./TagAdder";
import { WishListContext } from "./WishListContext";

interface Props {
  closeFunction: Function;
  existingItem?: WishListProps;
}

const AddWishList: FC<Props> = ({ closeFunction, existingItem }) => {
  const { itemsCollectionRef, listName } = useContext(WishListContext);
  const [selectedTags, setSelectedTags] = useState<TagSchema[]>(
    existingItem?.tags || []
  );
  const [form] = Form.useForm();

  // clear fields from previous item
  // used when we open drawer and close without saving
  useEffect(() => {
    form.resetFields();
  }, [form, existingItem])

  const addItem = async (values: any) => {
    const fields = {
      name: values.name,
      price: values.price,
      link: values.link,
      image: values.image || null,
      rating: values.rating || 0,
      description: values.description || null,
      tags: selectedTags,
    };

    if (existingItem) {
      updateItem(fields);
    } else {
      await addDoc(
        itemsCollectionRef,
        Object.assign(fields, { date: new Date() })
      );
    }

    //clear fields
    form.resetFields();

    //clear selected tags (above clear doesn't reset this)
    setSelectedTags([]);

    //close drawer
    closeFunction();
  };

  const updateItem = async (fields: any) => {
    if (existingItem) {
      const itemDoc = doc(firestoreDB, listName, existingItem?.id);
      await updateDoc(itemDoc, fields);
    }
  };

  return (
    <Form form={form} onFinish={addItem} autoComplete="off">
      <Form.Item
        label="Item Name"
        name="name"
        rules={[requiredRule]}
        initialValue={existingItem?.name}
      >
        <Input maxLength={28} showCount />
      </Form.Item>

      <Form.Item
        label="Price"
        name="price"
        rules={[requiredRule]}
        initialValue={existingItem?.price}
      >
        <InputNumber prefix="$" />
      </Form.Item>

      <Form.Item
        label="Buy Link"
        name="link"
        rules={[requiredRule]}
        initialValue={existingItem?.link}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Image URL"
        name="image"
        initialValue={existingItem?.image}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Rating"
        name="rating"
        initialValue={existingItem?.rating}
      >
        <Rate character={<HeartOutlined />} />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        initialValue={existingItem?.description}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="Tags" name="tags">
        <TagAdder
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      </Form.Item>

      <Form.Item>
        <Button block type="primary" htmlType="submit">
          {existingItem ? "Update" : "Add to List"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddWishList;
