import { BannerProps } from './Interfaces';

export enum BannerTypes {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export const HomeBanner: BannerProps = {
  visible: false,
  message: 'Introducing Brian - an AI chatbot',
  link: '/brian',
  bannerType: BannerTypes.SUCCESS,
};
