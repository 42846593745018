import { getAnalytics, logEvent } from "firebase/analytics";
import { Timestamp } from "firebase/firestore";
import { ModelArticle } from "../constants/Interfaces";
import { getCookie } from "typescript-cookie";

export const openURL = (url: string) => {
  const analytics = getAnalytics();
  logEvent(analytics, "Clicked Link", { url: url });
  window.open(url);
};

export const getScreenWidth = (): number => {
  const { innerWidth } = window;
  return innerWidth;
};

export const formDateString = (input: Timestamp | undefined): string => {
  const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let dateString = "";

  if (input !== undefined) {
    const newDate = input.toDate();

    const month = newDate.getMonth();
    const day = newDate.getDate();
    const year = newDate.getFullYear();

    dateString = monthList[month] + " " + day + ", " + year;
  }

  return dateString;
};

export const dateToTimestamp = (date: Date): Timestamp => {
  return Timestamp.fromDate(date);
}

export const removeHiddenArticles = (articles: ModelArticle[]): ModelArticle[] => {
  return articles.filter(article => article.active !== false);
};

export const getDarkModeToggle = (): boolean => {
  const toggleStatus = getCookie('darkMode');
  const toggleBoolean: boolean = toggleStatus === 'false' ? false : true;

  return toggleBoolean;
};