import { faBell, faFaceFrown, faFaceSmile } from "@fortawesome/free-solid-svg-icons";
import SoftwareFragment from "../fragments/SoftwareFragment";

const Blue = () => {
  return (
    <SoftwareFragment
      appName={"Blue"}
      appIcon={"./images/Blue/icon.jpg"}
      appLink={"https://apps.apple.com/us/app/blue-mood-tracker/id6444869248"}
      subTitle={"Mood Tracker"}
      description={
        "Easily track your mental health and mood throughout the day with Blue. It’s a simple, easy to use, beautifully designed app to track moods, feelings, and other thoughts you have. It features over 15 mood faces, 8 colors to choose from, and custom feelings. \nSimple pick a face & color and select all of the emotions that apply. Or enter a custom one! Then enter any notes or thoughts you’re having. Easily review previous days and track how your mood is changing over time.\nBlue also features reminders to get notified throughout the day to track your mood. You can enable or disable each time individually and customize when you’d like the get reminded."
      }
      images={[
        "./images/Blue/Screenshot 1.png",
        "./images/Blue/Screenshot 2.png",
        "./images/Blue/Screenshot 3.png",
        "./images/Blue/Screenshot 4.png",
      ]}
      features={[
        {
          title: "Face Options",
          description: "Over 15 faces to pick from to express your mood.",
          icon: faFaceSmile,
        },
        {
          title: "Mood Tracking",
          description:
            "Easily go back in time to see how your mood has changed over time.",
          icon: faFaceFrown,
        },
        {
          title: "Notifications",
          description:
            "Get notifications and reminders throughout the day to log your mood.",
          icon: faBell,
        },
      ]}
      price={"Free"}
      supportedVersion={"iOS 15+"}
      size={"1.8 MB"}
    />
  );
};

export default Blue;
