import { useContext, useState } from 'react';
import { AuthContext } from '../AuthContext';
import AuthPage from '../AuthPage';
import UserSelect from './UserSelect';
import WishListProvider from './WishListContext';
import ListMenuBar from './ListMenuBar';
import WishList from './WishList';
import DocumentTitle from 'react-document-title';

const WishListLanding = () => {
  const { user, loading } = useContext(AuthContext);

  const [listName, setListName] = useState('');

  return (
    <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
      <DocumentTitle title="Wish List - Ryan Hunter" />
      {user ? (
        <>
          {listName === '' ? (
            <UserSelect setFunction={setListName} />
          ) : (
            <WishListProvider listName={listName}>
              <ListMenuBar setListFunction={setListName} />
              <WishList />
            </WishListProvider>
          )}
        </>
      ) : (
        <AuthPage />
      )}
    </div>
  );
};

export default WishListLanding;
