import { Image, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import LoadingSkeletonSmall from "./LoadingSkeletonSmall";
import { ArticleContext } from "../constants/ArticleContext";
import { formDateString, removeHiddenArticles } from "../fragments/Helpers";

const LatestArticle = () => {
  const { articles, loading } = useContext(ArticleContext);
  const navigate = useNavigate();
  const article = removeHiddenArticles(articles)[0];

  return (
    <>
      {loading ? (
        <LoadingSkeletonSmall />
      ) : (
        <div
          onClick={() => navigate(`/models/${article?.slug}`)}
          style={{
            cursor: "pointer",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "10px",
            width: "fit-content",
            borderRadius: "10px",
            border: "1px solid #303030",
            padding: "10px",
            margin: "auto",
          }}
        >
          <Image
            width={250}
            src={article?.images[0]}
            preview={false}
            fallback="./images/blank.jpg"
          />
          <div>
            <Typography.Title level={5}>Latest Article</Typography.Title>
            <Typography.Title level={3}>{article?.title}</Typography.Title>
            <Typography.Text type="secondary">
              {formDateString(article?.date)}
            </Typography.Text>
          </div>
        </div>
      )}
    </>
  );
};

export default LatestArticle;
