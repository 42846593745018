import { Avatar, Flex, Typography } from "antd";
import { FC } from "react";
import { getListForUser } from "./utils";

interface Props {
  setFunction: Function;
}

const UserSelect: FC<Props> = ({ setFunction }) => {
  const setList = (user: string) => {
    setFunction(getListForUser(user));
    const params = new URLSearchParams(window.location.search);
    params.set("user", user);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography.Title>Select a List to View</Typography.Title>
      <Flex>
        <Avatar
          size={128}
          src="./images/Memoji.png"
          onClick={() => setList("ryan")}
          style={{ cursor: "pointer" }}
        />
        <Avatar
          size={128}
          src="./images/mom.png"
          onClick={() => setList("mom")}
          style={{ cursor: "pointer" }}
        />
      </Flex>
    </div>
  );
};

export default UserSelect;
