import { faDatabase, faFilter, faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import SoftwareFragment from "../fragments/SoftwareFragment";

const GeneAssist = () => {
  return (
    <SoftwareFragment
      appName={"Gene Assist"}
      appIcon={"./images/GeneAssist/icon.png"}
      appLink={"https://apps.apple.com/us/app/geneassist/id6446294808"}
      subTitle={"Family Medical Condition Tracker"}
      description={
        "Track your entirely family's medical history in one place. Never forget a previous medical condition. Perfect for visiting the doctor and filling out new patient forms. This beautifully designed app allows you to track medical history going back two generations. You can easily filter by category to restrict the list to the doctor you're currently visiting."
      }
      images={[
        "./images/GeneAssist/Screenshot 1.png",
        "./images/GeneAssist/Screenshot 2.png",
        "./images/GeneAssist/Screenshot 3.png",
        "./images/GeneAssist/Screenshot 4.png",
      ]}
      features={[
        {
          title: "Filters",
          description: "Color coded filters by medical condition category",
          icon: faFilter,
        },
        {
          title: "Built-in Database",
          description: "Contains nearly 1,000 medical conditions",
          icon: faDatabase,
        },
        {
          title: "Custom Family Members",
          description:
            "Add custom family members to track multiple generations and people",
          icon: faPeopleGroup,
        },
      ]}
      price="$0.99"
      supportedVersion="iOS 15+"
      size="1.1 MB"
    />
  );
};

export default GeneAssist;
