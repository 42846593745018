import { User, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { FC, createContext, useEffect, useState } from 'react';
import { firebaseAuth } from '../firebaseConfig';

interface AuthContextInterface {
  user: User | undefined;
  setUser: (user: User) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  error: string;
  logIn: (values: any) => Promise<void>;
}

interface Props {
  children: React.ReactNode;
}

export const AuthContext = createContext({} as AuthContextInterface);

const AuthProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, currentUser => {
      setLoading(false);
      setUser(currentUser as any);
    });
  }, []);

  const logIn = async (values: any) => {
    try {
      await signInWithEmailAndPassword(firebaseAuth, values.email, values.password);
    } catch (error: any) {
      let errorText = 'Invalid email or password';
      if (error.code === 'auth/user-not-found') {
        errorText = 'User not found';
      } else if (error.code === 'auth/wrong-password') {
        errorText = 'Incorrect password';
      }
      setError(errorText);
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUser, loading, setLoading, error, logIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
