import { PlusOutlined } from "@ant-design/icons";
import { Space, Tag } from "antd";
import { FC } from "react";

export interface TagSchema {
  name: string;
  color: string;
}

export enum WishListTags {
  CHRISTMAS = "Christmas",
  BIRTHDAY = "Birthday",
  DONOTBUY = "Do Not Buy",
  SELF = "Buy for Self",
  PRIMEDAY = "Prime Day",
}

const AdderTags: TagSchema[] = [
  {
    name: WishListTags.CHRISTMAS,
    color: "red",
  },
  {
    name: WishListTags.BIRTHDAY,
    color: "blue",
  },
  {
    name: WishListTags.PRIMEDAY,
    color: "cyan",
  },
  {
    name: WishListTags.DONOTBUY,
    color: "default",
  },
  {
    name: WishListTags.SELF,
    color: "gold",
  },
];

interface Props {
  selectedTags: TagSchema[];
  setSelectedTags: Function;
}

const TagAdder: FC<Props> = ({ selectedTags, setSelectedTags }) => {
  //remove selected tags from wishlist tags
  const remainingTags = AdderTags.filter((tag) => !selectedTags.includes(tag));

  const addTag = (tag: TagSchema) => {
    setSelectedTags([...selectedTags, tag]);
  };

  const removeTag = (tag: TagSchema) => {
    setSelectedTags(selectedTags.filter((t) => t !== tag));
  };

  return (
    <div>
      <Space wrap style={{ marginBottom: "10px" }}>
        {selectedTags.map((tag) => (
          <Tag closable onClose={() => removeTag(tag)} color={tag.color}>
            {tag.name}
          </Tag>
        ))}
      </Space>

      <Space wrap>
        {remainingTags.map((tag) => (
          <Tag
            icon={<PlusOutlined />}
            color={tag.color}
            onClick={() => addTag(tag)}
          >
            {tag.name}
          </Tag>
        ))}
      </Space>
    </div>
  );
};

export default TagAdder;
