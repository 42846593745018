import { Carousel, Col, Image, Progress, Row, Typography } from 'antd';
import { useContext } from 'react';
import LoadingSkeletonSmall from './LoadingSkeletonSmall';
import { ArticleContext } from '../constants/ArticleContext';

const ModelProgress = () => {
  const { projects, loading } = useContext(ArticleContext);

  //filter out inactive projects
  const availableProjects = projects.filter(project => project.active);

  return (
    <Carousel autoplay>
      {availableProjects.map(project => {
        const isOnHold = project.hold;
        const statusType = () => {
          if (project.progress === 100) {
            return 'success';
          } else if (isOnHold) {
            return 'exception';
          }
          return 'active';
        };

        return (
          <div key={project.id}>
            {loading ? (
              <LoadingSkeletonSmall />
            ) : (
              <Row
                key={project.id}
                style={{
                  width: 'fit-content',
                  margin: 'auto',
                  gap: '20px',
                  alignItems: 'center',
                  borderRadius: '10px',
                  border: '1px solid #303030',
                  padding: '0px 10px 10px 10px',
                  marginBottom: '10px',
                }}
              >
                <Col>
                  <Typography.Title level={5} type='secondary'>
                    Current Projects
                  </Typography.Title>
                  <Image
                    width={150}
                    preview={false}
                    src={project?.image}
                    fallback='./images/blank.jpg'
                  />
                </Col>

                <Col>
                  <Typography.Title>{project?.name}</Typography.Title>
                  <Progress percent={project?.progress} status={statusType()} />
                  <Typography.Text>
                    {isOnHold ? 'Project on Hold' : project?.description}
                  </Typography.Text>
                </Col>
              </Row>
            )}
          </div>
        );
      })}
    </Carousel>
  );
};

export default ModelProgress;
