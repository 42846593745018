import { faBars, faHandPointUp, faMoon } from "@fortawesome/free-solid-svg-icons";
import SoftwareFragment from "../fragments/SoftwareFragment";

const JotSpotmacOS = () => {
  return (
    <SoftwareFragment
      appName={"JotSpot macOS"}
      appIcon={"./images/JotSpotmacOS/icon.png"}
      appLink={
        "https://geo.itunes.apple.com/us/app/jotspot-simple-word-processor/id1022477396?mt=12&at=10lNVd"
      }
      subTitle={
        "Simple, Functional, And Beautifully Designed Text Editor And Notepad"
      }
      description={
        "JotSpot is an amazing app that lets you jot down some quick notes, ideas, shopping lists, and more. With the quick-access menu icon you can jot down things even quicker! Then when you’re done print out a final copy.\nJotSpot features things like Dark Mode for writing at night, a simple and clean interface to go with OS X Yosemite, amazing support, and much, much more."
      }
      images={[
        "./images/JotSpotmacOS/JotSpot 1.png",
        "./images/JotSpotmacOS/JotSpot 2.png",
        "./images/JotSpotmacOS/JotSpot 3.png",
      ]}
      features={[
        {
          title: "Dark Mode",
          description:
            "Both the macOS and iOS versions feature Dark Mode for writing at night.",
          icon: faMoon,
        },
        {
          title: "Touch Bar",
          description:
            "JotSpot for macOS has support for the Touch Bar on the new MacBook Pro. You can change font colors, add emojis, and more!",
          icon: faHandPointUp,
        },
        {
          title: "Menu Item",
          description:
            "JotSpot for macOS features a menu item, which allows you to take notes while in other apps!",
          icon: faBars,
        },
      ]}
      price={"$0.99"}
      supportedVersion={"macOS 10.10+"}
      size={"5 MB"}
    />
  );
};

export default JotSpotmacOS;
