import { faCloud, faMoon } from "@fortawesome/free-solid-svg-icons";
import SoftwareFragment from "../fragments/SoftwareFragment";

const JotSpotiOS = () => {
  return (
    <SoftwareFragment
      appName={"JotSpot iOS"}
      appIcon={"./images/JotSpotiOS/icon.png"}
      appLink={
        "https://itunes.apple.com/us/app/jotspot-a-simple-text-editor/id1121461081?mt=8&at=10lNVd"
      }
      subTitle={
        "Simple, Functional, And Beautifully Designed Text Editor And Notepad"
      }
      description={
        "JotSpot is an amazing app that lets you jot down some quick notes, ideas, shopping lists, and more. JotSpot features things like Dark Mode for writing at night, a simple and clean interface, amazing support, and much, much more. Other features include iCloud sync, ability to share your notes anywhere, split view on iPads, and much more."
      }
      images={[
        "./images/JotSpotiOS/1.png",
        "./images/JotSpotiOS/2.png",
        "./images/JotSpotiOS/3.png",
        "./images/JotSpotiOS/4.png",
      ]}
      features={[
        {
          title: "iCloud Sync",
          description:
            "JotSpot for iOS features iCloud sync so you can access and edit your notes from any device.",
          icon: faCloud,
        },
        {
          title: "Dark Mode",
          description:
            "Both the macOS and iOS versions feature Dark Mode for writing at night.",
          icon: faMoon,
        },
      ]}
      price={"Free"}
      supportedVersion={"iOS 15+"}
      size={"1.1 MB"}
    />
  );
};

export default JotSpotiOS;
