import { Skeleton } from "antd";

const LoadingSkeletonSmall = () => {
  return (
    <div style={{ display: "flex", width: "30%", margin: "auto", gap: "20px" }}>
      <Skeleton.Image active />
      <Skeleton active title paragraph={{ rows: 2 }} />
    </div>
  );
};

export default LoadingSkeletonSmall;
