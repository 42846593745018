import { Avatar, Col, Collapse, Divider, Row, Space, Typography } from "antd";
import Banner from "../components/Banner";
import { HomeBanner } from "../constants/Banners";
import LatestArticle from "../components/LatestArticle";
import ModelProgress from "../components/ModelProgress";

const { Title, Text } = Typography;

const Home = () => {
  return (
    <div>
      <Banner bannerProps={HomeBanner} />
      <ModelProgress />
      <LatestArticle />

      <Divider />

      <Row>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Avatar
            size={256}
            src={<img src="./images/Memoji.png" alt="Memoji" />}
          />
        </Col>
        <Col span={16}>
          <Title>Ryan Hunter</Title>
          <Title level={3}>Software Engineer, Pilot, and Filmmaker</Title>

          <Divider />

          <Space direction="vertical">
            <Text>
              Welcome to my website. This is the hub of information about me and
              a collection of all of my work. You'll find my various software
              projects and apps available for download. You can also find some
              of the things I'm selling, including aviation-themed shirts I
              designed and a macro keypad that I make.
            </Text>
          </Space>
        </Col>
      </Row>

      <Collapse
        accordion
        defaultActiveKey={["1"]}
        style={{ marginTop: "15px" }}
      >
        <Collapse.Panel header="About Me" key="1">
          <Space direction="vertical">
            <Text>
              I am a software engineer with a passion for creating. In my free
              time, I'm a pilot, make apps, do graphic design, photography, and
              filmmaking.
            </Text>
            <Text>
              I am a graduate of the University of Portland with a degree in
              Computer Science. I have been working as a software engineer at
              Nike since 2022, and have experience in a variety of programming
              languages and technologies. I'm also an avid learner, and am
              always looking for new ways to improve my skills.
            </Text>

            <Text>
              In my free time, I enjoy flying airplanes. I am a licensed
              instrument private pilot, and love the feeling of being up in the
              air. I also enjoy making apps, and have developed several apps
              that have been downloaded by thousands of people. I'm also a
              graphic designer, photographer, and filmmaker. I have created a
              number of impressive pieces of work, and am always looking for new
              ways to express my creativity.
            </Text>

            <Text>
              I am a creative and driven individual with a passion for
              technology. I am always looking for new challenges, and am excited
              to see what the future holds.
            </Text>

            <Text code>- Written by ChatGPT 🤘</Text>
          </Space>
        </Collapse.Panel>

        <Collapse.Panel header="What is N818PE?" key="2">
          <Space direction="vertical">
            <Text>
              N818PE is the tail number of my airplane... or will be when I get
              one 😉
            </Text>

            <Text>
              I'm an instrument rated pilot. I enjoy flying and would love to
              own an airplane one day. But until then, I reserved a tail number
              to assign to that plane when I get it.
            </Text>

            <Text>
              In aviation, we use tail number as an identifier when talking to
              ATC. You say that tail number a lot on the radio, and I wanted
              something that was easy to say and not something that could get
              read back wrong. After thinking about what sounds best, along with
              a combination of my favorite numbers, I landed in N818PE. Easy to
              say, easy to remember, and meaningful.
            </Text>
          </Space>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default Home;
