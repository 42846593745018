import './App.css';
import { Button, Divider, Layout, Menu, MenuProps, Space, Switch, theme, Typography } from 'antd';
import { mapPageToName, NavigationLinks, PageRoutes } from './constants/Routing';
import { useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import {
  FacebookOutlined,
  GithubOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MenuOutlined,
  MoonOutlined,
  SunOutlined,
} from '@ant-design/icons';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { setCookie } from 'typescript-cookie';
import { getDarkModeToggle } from './fragments/Helpers';

const { Header, Content, Footer } = Layout;
const { Text } = Typography;

function App() {
  const analytics = getAnalytics();
  const [current, setCurrent] = useState('');
  const [darkMode, setDarkMode] = useState(getDarkModeToggle());

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    setCookie('darkMode', !darkMode, { expires: 90 });
    window.location.reload();
  };

  //used to auto highlight the tab on refresh
  useEffectOnce(() => {
    const path = window.location.pathname.substring(1);

    if (path !== '') {
      setPageTitle(path);
      setCurrent(path);
    } else {
      setCurrent('home');
    }
  });

  const setPageTitle = (title: string) => {
    let newTitle = 'Ryan Hunter';

    if (title !== 'home') {
      const pageName = mapPageToName(title);

      if (pageName) {
        newTitle = `${pageName} - Ryan Hunter`;
      }
    }

    document.title = newTitle;
  };

  const handleClick: MenuProps['onClick'] = e => {
    logEvent(analytics, 'Navigated to new page', { pageName: e.key });

    setPageTitle(e.key);

    setCurrent(e.key);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout className="layout">
      <Header>
        <Menu
          theme="dark"
          mode="horizontal"
          items={NavigationLinks}
          onClick={handleClick}
          selectedKeys={[current]}
          overflowedIndicator={<MenuOutlined />}
        />
      </Header>
      <Content style={{ padding: '0 30px' }}>
        <div className="site-layout-content" style={{ background: colorBgContainer }}>
          <PageRoutes />
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>

        <Switch checked={darkMode} checkedChildren={<MoonOutlined />} unCheckedChildren={<SunOutlined />} onChange={toggleDarkMode} />

        <Divider />

        <Space>
          <Button
            icon={<LinkedinOutlined />}
            href="https://www.linkedin.com/in/ryanhunter12/"
            target="_blank"
          />
          <Button icon={<GithubOutlined />} href="https://github.com/iap128" target="_blank" />
          <Button
            icon={<InstagramOutlined />}
            href="https://www.instagram.com/n818pe/"
            target="_blank"
          />
          <Button
            icon={<FacebookOutlined />}
            href="https://www.facebook.com/profile.php?id=61556326617060"
            target="_blank"
          />
        </Space>

        <Divider />

        <Text> Made with ❤️ by Ryan Hunter</Text>
      </Footer>
    </Layout>
  );
}

export default App;
