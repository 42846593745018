import ProductsPage from "../fragments/ProductsPage";

const Shirts = () => {
  return (
    <ProductsPage
      pageTitle="Shirts"
      products={[
        {
          title: "Zenith CH 701 STOL",
          image: "./images/Shirts/701.png",
          url: "https://cottonbureau.com/p/ECKNNV/shirt/zenith-ch-701-stol#/18723472",
          ribbon: "New",
        },
        {
          title: "Cow Biosensor",
          image: "./images/Shirts/biosensor.png",
          url: "https://cottonbureau.com/p/FP43Q3/shirt/cow-biosensor#/17252512",
        },
        {
          title: "Airplane Attitude Indicator",
          image: "./images/Shirts/attitude.png",
          url: "https://cottonbureau.com/p/QPZJCA/shirt/airplane-attitude-indicator#/16643356",
        },
        {
          title: "Airplane Turn Coordinator",
          image: "./images/Shirts/turn-coordinator.png",
          url: "https://cottonbureau.com/p/F6EM5W/shirt/airplane-turn-coordinator#/16650210",
        },
        {
          title: "Airplane Airspeed Indicator",
          image: "./images/Shirts/airspeed.png",
          url: "https://cottonbureau.com/p/9J86MK/shirt/airplane-airspeed-indicator#/16652400",
        },
        {
          title: "Cessna 150/152",
          image: "./images/Shirts/150.png",
          url: "https://cottonbureau.com/p/PDHARF/shirt/cessna-150152#/16461044",
        },
        {
          title: "Cessna 172 Skyhawk",
          image: "./images/Shirts/172.png",
          url: "https://cottonbureau.com/p/A3PGXS/shirt/cessna-172-skyhawk#/16475082",
        },
        {
          title: "Tecnam P2006T",
          image: "./images/Shirts/tecnam.png",
          url: "https://cottonbureau.com/p/HKJV83/shirt/tecnam-p2006t#/16513625",
        },
        {
          title: "Piper PA-28 Cherokee",
          image: "./images/Shirts/cherokee.png",
          url: "https://cottonbureau.com/p/44PANJ/shirt/piper-pa-28-cherokee#/16631075",
        },
        {
          title: "Beechcraft Bonanza V35",
          image: "./images/Shirts/bonanza.png",
          url: "https://cottonbureau.com/p/UFST5W/shirt/beechcraft-bonanza-v35#/16631273",
        },
      ]}
    />
  );
};

export default Shirts;
