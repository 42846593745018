import { faChartSimple, faClockRotateLeft, faCloud } from '@fortawesome/free-solid-svg-icons';
import SoftwareFragment from '../fragments/SoftwareFragment';

const Pinwheel = () => {
  return (
    <SoftwareFragment
      appName={'Pinwheel'}
      appIcon='./images/Pinwheel/icon.png'
      appLink={'https://pinwheel-128.web.app'}
      subTitle={'PWS Viewer'}
      description={"Pinwheel is a personal weather station (PWS) viewer tool. It supports weather stations connected to Weather Underground. You'll need your station ID to load the data. Once entered, you'll be able to view live values from the weather station, a graph of how conditions have changed throughout the day, and a 7 day history for min and max values."}
      images={["./images/Pinwheel/1.png", "./images/Pinwheel/2.png", "./images/Pinwheel/3.png"]}
      features={[
        {
            title: "Live View",
            description: "Get a live view of the current weather conditions.",
            icon: faCloud
        },
        {
            title: "Conditions Graph",
            description: "View a graph of how conditions have changed throughout the day.",
            icon: faChartSimple
        },
        {
            title: "7 Day History",
            description: "View a 7 day history for min and max values.",
            icon: faClockRotateLeft
        }
      ]}
      price={'Free'}
      supportedVersion={'Web'}
      size={'N/A'}
    />
  );
};

export default Pinwheel;
