import { Image, Typography } from "antd";
import { getAnalytics, logEvent } from "firebase/analytics";

const Error = () => {
  const analytics = getAnalytics();
  logEvent(analytics, "Navigated to error page");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography.Title>Oops</Typography.Title>
      <Typography.Title level={3} keyboard>
        This is not the page you were looking for...
      </Typography.Title>

      <Image
        preview={false}
        src="https://media.tenor.com/9UXwh7MKQ08AAAAC/starwars-movealong.gif"
      />
    </div>
  );
};

export default Error;
