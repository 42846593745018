import { Alert } from "antd";
import { FC } from "react";
import { BannerProps } from "../constants/Interfaces";
import { Link } from "react-router-dom";
import { getCookie, setCookie } from "typescript-cookie";
import { getAnalytics, logEvent } from "firebase/analytics";

interface Props {
  bannerProps: BannerProps;
}

const Banner: FC<Props> = ({ bannerProps }) => {
  const analytics = getAnalytics();
  const hideBanner =
    bannerProps.visible === false || getCookie(bannerProps.message);

  //cleanup function that will log an analytics event
  //and set a cookie so it doesn't come back
  const closedCleanup = () => {
    logEvent(analytics, "Dismissed banner", {
      bannerName: bannerProps.message,
    });
    setCookie(bannerProps.message, "true");
  };

  return (
    <>
      {!hideBanner && (
        <Alert
          closable
          banner
          type={bannerProps.bannerType}
          message={bannerProps.message}
          action={<Link to={bannerProps.link}>Learn More</Link>}
          afterClose={closedCleanup}
        />
      )}
    </>
  );
};

export default Banner;
