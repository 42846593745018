import { faBrain, faRobot, faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import SoftwareFragment from "../fragments/SoftwareFragment"

const Brian = () => {
    return (
        <SoftwareFragment
            appName="Brian"
            appIcon="./images/Brian/icon.png"
            appLink="https://ask-brian.web.app"
            subTitle="AI Chat Bot"
            description="Brian is an AI chat bot using a multimodal AI language model developed by Google. It can assist with all common AI tasks, such as text generation, language understanding, code generation, and much more."
            images={["./images/Brian/1.png", "./images/Brian/2.png"]}
            features={[
                {
                    title: "Google Gemini Model",
                    description: "Built using Google's Gemini Pro model",
                    icon: faRobot
                },
                {
                    title: "Secure",
                    description: "No data is saved or stored. Your conversation is wiped when you leave the page.",
                    icon: faShieldHalved
                },
                {
                    title: "Context Aware",
                    description: "Brian can remember what you were talking about and continue the conversation.",
                    icon: faBrain
                }
            ]}
            price="Free"
            supportedVersion="Web"
            size="N/A"
        />
    )
};

export default Brian;