import { useContext, useState } from 'react';
import { Typography, Row, Col, Card, Image, Button, Space, Pagination, Badge, Rate } from 'antd';
import LoadingSkeleton from '../components/LoadingSkeleton';
import { useNavigate } from 'react-router-dom';
import { ArticleContext } from '../constants/ArticleContext';
import { formDateString, removeHiddenArticles } from '../fragments/Helpers';
import { ReadOutlined } from '@ant-design/icons';

// Global variables for models page
const articlesPerPage = 6;

const ModelsPage = () => {
  const navigate = useNavigate();
  const { articles } = useContext(ArticleContext);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const visibleArticles = removeHiddenArticles(articles);

  // get the 6 articles for this page
  const startIndex = (currentPage - 1) * articlesPerPage;
  const endIndex = startIndex + articlesPerPage;
  const articlesForPage = visibleArticles.slice(startIndex, endIndex);

  return (
    <div>
      <Typography.Title>Scale Model Kit Reviews</Typography.Title>
      <Row gutter={16}>
        {articlesForPage.map(model => (
          <Col
            key={model.slug}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{ paddingBottom: '8px' }}
          >
            <div hidden={loading}>
              <Badge.Ribbon
                color="gold"
                text={model.award ? 'Award Winner' : ''}
                style={{ visibility: model.award ? 'visible' : 'hidden' }}
              >
                <Card
                  hoverable={true}
                  cover={
                    <Image
                      src={model.images[0]}
                      fallback="./images/blank.jpg"
                      preview={false}
                      onClick={() => navigate(`/models/${model.slug}`)}
                      onLoad={() => setLoading(false)}
                    />
                  }
                  actions={[<Button onClick={() => navigate(`/models/${model.slug}`)} icon={<ReadOutlined />}>Read More</Button>]}
                >
                  <Card.Meta
                    title={model.title}
                    description={
                      <Space direction="vertical">
                        <Typography.Text type='secondary'>{formDateString(model.date)}</Typography.Text>
                        <Rate allowHalf disabled value={model.rating} />
                      </Space>
                    }
                  />
                </Card>
              </Badge.Ribbon>
            </div>

            {loading && <LoadingSkeleton />}
          </Col>
        ))}
      </Row>
      <Pagination
        style={{ display: 'flex', justifyContent: 'center' }}
        current={currentPage}
        pageSize={articlesPerPage}
        total={visibleArticles.length}
        onChange={page => setCurrentPage(page)}
      />
    </div>
  );
};

export default ModelsPage;
