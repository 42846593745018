import { FC } from "react";
import { AppFeature } from "../constants/Interfaces";
import {
  Typography,
  Button,
  Image,
  Card,
  Row,
  Col,
  Divider,
  Statistic,
} from "antd";
import {
  DownloadOutlined,
  DollarOutlined,
  MobileOutlined,
  FileZipOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Title, Text } = Typography;

interface Props {
  appName: string;
  appIcon: string;
  appLink: string;
  subTitle: string;
  description: string;
  images: string[];
  features: AppFeature[];
  price: string;
  supportedVersion: string;
  size: string;
}

const SoftwareFragment: FC<Props> = ({
  appName,
  appIcon,
  appLink,
  subTitle,
  description,
  images,
  features,
  price,
  supportedVersion,
  size,
}) => {
  const imageWidth = (): string => {
    if (images.length % 4 === 0) {
      return "25%";
    }
    return "33%";
  };
  const colSpan = (): number => {
    if (features.length === 2) {
      return 12;
    }
    return 8;
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Image src={appIcon} preview={false} width={100} />
        <Title>{appName}</Title>
        <Title level={3}>{subTitle}</Title>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          size="large"
          href={appLink}
          target="_blank"
        >
          Download
        </Button>
      </div>

      <Divider />

      <Row gutter={16}>
        <Col span={8}>
          <Statistic title="Price" value={price} prefix={<DollarOutlined />} />
        </Col>
        <Col span={8}>
          <Statistic
            title="Supported OS"
            value={supportedVersion}
            prefix={<MobileOutlined />}
          />
        </Col>
        <Col span={8}>
          <Statistic title="Size" value={size} prefix={<FileZipOutlined />} />
        </Col>
      </Row>

      <Divider />

      <Image.PreviewGroup>
        {images.map((image) => (
          <Image key={image} src={image} width={imageWidth()} />
        ))}
      </Image.PreviewGroup>

      <Divider />

      <Text>{description}</Text>

      <Divider />

      <Row gutter={16}>
        {features.map((feature) => (
          <Col
            key={feature.title}
            xs={24}
            sm={24}
            md={colSpan()}
            lg={colSpan()}
            xl={colSpan()}
          >
            <Card title={feature.title} extra={<FontAwesomeIcon icon={feature.icon} />}>
              <Text>{feature.description}</Text>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default SoftwareFragment;
