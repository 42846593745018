import { Button, Form, Input, Typography, message } from "antd";
import { emailRule, requiredRule } from "../constants/FormRules";

const Contact = () => {
  const formEndpoint =
    "https://public.herotofu.com/v1/d65368b0-e5e7-11ed-8300-fd92f9e8911a";

  const [form] = Form.useForm();
  const [messageAPI, contextHolder] = message.useMessage();

  const onFinish = (values: any) => {
    fetch(formEndpoint, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application.json",
      },
      body: JSON.stringify(values),
    }).then((response) => {
      if (response.status === 200) {
        form.resetFields();

        messageAPI.open({
          type: "success",
          content: "Message sent. Thanks!",
        });
      } else {
        messageAPI.open({
          type: "error",
          content: response.statusText,
        });
      }
    });
  };

  return (
    <div>
      <Typography.Title>Contact Me</Typography.Title>
      <Typography.Text>
        Enter your info below and I'll get back to you as soon as possible
      </Typography.Text>

      {contextHolder}
      <Form
        form={form}
        autoComplete="off"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        style={{ marginTop: "25px" }}
      >
        <Form.Item label="First Name" name="firstName" rules={[requiredRule]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Email Address"
          name="email"
          rules={[emailRule, requiredRule]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Message" name="message" rules={[requiredRule]}>
          <Input.TextArea autoSize={{ minRows: 6 }} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Contact;
