import { useContext, useState } from "react";
import { useEffectOnce } from "usehooks-ts";
import { Row, Col, Divider, Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AddWishList from "./AddWishList";
import WishListItem from "./WishListItem";
import { WishListContext } from "./WishListContext";

const WishList = () => {
  const [openDrawer, setDrawerOpen] = useState(false);
  const { items, getItems } = useContext(WishListContext);

  useEffectOnce(() => {
    getItems();
  });

  const closeDrawer = () => {
    setDrawerOpen(false);
    getItems();
  };

  return (
    <>
      <Button
        type="primary"
        block
        icon={<PlusOutlined />}
        onClick={() => setDrawerOpen(true)}
      >
        Add Wish List Item
      </Button>

      <Divider />

      <Row gutter={16}>
        {items.map((item) => {
          return (
            <Col
              key={item.id}
              xs={24}
              sm={24}
              md={8}
              lg={6}
              xl={6}
              style={{ paddingBottom: "8px" }}
            >
              <WishListItem item={item} />
            </Col>
          );
        })}
      </Row>

      <Modal
        title="Add Wish List Item"
        onCancel={() => setDrawerOpen(false)}
        onOk={() => setDrawerOpen(false)}
        open={openDrawer}
        footer={null}
      >
        <AddWishList closeFunction={closeDrawer} />
      </Modal>
    </>
  );
};

export default WishList;
