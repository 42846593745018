export const getListForUser = (userId: string): string => {
  switch (userId) {
    case "ryan":
      return "wishList";
    case "mom":
      return "wishList-mom";
    default:
      return "";
  }
};
