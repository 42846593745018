import { useContext } from 'react';
import { ArticleContext, CurrentProjectInterface } from '../../constants/ArticleContext';
import { Button, Card, Divider, Image, Popconfirm, Table, TableProps, Typography } from 'antd';
import { ModelArticle } from '../../constants/Interfaces';
import {
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import AuthPage from '../AuthPage';
import { CollectionReference, DocumentData, deleteDoc, doc } from 'firebase/firestore';
import { formDateString } from '../../fragments/Helpers';
import DocumentTitle from 'react-document-title';

const CMS = () => {
  const navigate = useNavigate();
  const { articles, projects, modelCollectionRef, currentProjectCollectionRef, loadData } =
    useContext(ArticleContext);
  const { user, loading } = useContext(AuthContext);

  const deleteItem = async (reference: CollectionReference<DocumentData>, id: string) => {
    const itemDoc = doc(reference, id);
    await deleteDoc(itemDoc);

    loadData();
  };

  const ArticleColumns: TableProps<ModelArticle>['columns'] = [
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'status',
      render: active =>
        active === false ? (
          <EyeInvisibleOutlined style={{ color: 'red' }} />
        ) : (
          <EyeOutlined style={{ color: 'green' }} />
        ),
    },
    {
      title: 'Image',
      dataIndex: 'images',
      key: 'image',
      render: images => <Image width='100px' src={images[0]} fallback='./images/blank.jpg' />,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title, record) => <Link to={`/models/${record.slug}`}>{title}</Link>,
    },
    {
      title: 'Difficulty',
      dataIndex: 'difficulty',
      key: 'difficulty',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: date => formDateString(date),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              navigate(`/cms/editor/article/${record?.id}`);
            }}
          />
          <Popconfirm
            title='Delete Article'
            description='Are you sure you want to delete this article?'
            onConfirm={() => deleteItem(modelCollectionRef, record.id)}
            okText='Delete'
          >
            <Button icon={<DeleteOutlined />} />
          </Popconfirm>
        </>
      ),
    },
  ];

  const ProjectColumns: TableProps<CurrentProjectInterface>['columns'] = [
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'status',
      render: active =>
        active === false ? (
          <EyeInvisibleOutlined style={{ color: 'red' }} />
        ) : (
          <EyeOutlined style={{ color: 'green' }} />
        ),
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: image => <Image width='100px' src={image} fallback='./images/blank.jpg' />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      key: 'progress',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              navigate(`/cms/editor/project/${record?.id}`);
            }}
          />
          <Popconfirm
            title='Delete Project'
            description='Are you sure you want to delete this project?'
            onConfirm={() => deleteItem(currentProjectCollectionRef, record.id)}
            okText='Delete'
          >
            <Button icon={<DeleteOutlined />} />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
      <DocumentTitle title='CMS - Ryan Hunter' />
      <Typography.Title>Article Management Dashboard</Typography.Title>
      {user ? (
        <>
          <Card
            title='Current Projects'
            extra={
              <Button
                type='primary'
                icon={<PlusCircleOutlined />}
                onClick={() => navigate(`/cms/editor/project/`)}
              />
            }
          >
            <Table bordered dataSource={projects} columns={ProjectColumns} />
          </Card>

          <Divider />

          <Card
            title='Model Articles'
            extra={
              <Button
                type='primary'
                icon={<PlusCircleOutlined />}
                onClick={() => navigate(`/cms/editor/article/`)}
              />
            }
          >
            <Table bordered dataSource={articles} columns={ArticleColumns} />
          </Card>
        </>
      ) : (
        <AuthPage />
      )}
    </div>
  );
};

export default CMS;
