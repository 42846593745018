import { Button, Form, Input, message } from 'antd';
import { useContext, useEffect } from 'react';
import { emailRule, requiredRule } from '../constants/FormRules';
import { AuthContext } from './AuthContext';
import DocumentTitle from 'react-document-title';

const AuthPage = () => {
  const { error, logIn } = useContext(AuthContext);
  const [messageAPI, contextHolder] = message.useMessage();

  useEffect(() => {
    if (error !== '') {
      messageAPI.open({ type: 'error', content: error });
    }
  }, [error, messageAPI]);

  return (
    <div>
      <DocumentTitle title='Log in - Ryan Hunter' />
      {contextHolder}

      <Form onFinish={logIn}>
        <Form.Item label='Email' name='email' rules={[requiredRule, emailRule]}>
          <Input type='email' />
        </Form.Item>

        <Form.Item label='Password' name='password' rules={[requiredRule]}>
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AuthPage;
