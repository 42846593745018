import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, theme } from 'antd';
import '@fontsource/poppins';
import ArticleProvider from './constants/ArticleContext';
import AuthProvider from './internal/AuthContext';
import { getDarkModeToggle } from './fragments/Helpers';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const darkMode = getDarkModeToggle();

if (!darkMode) {
  document.body.style.backgroundColor = 'white';
}

//more info on theming: https://ant.design/docs/react/customize-theme

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConfigProvider
        theme={{
          algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
          token: {
            fontFamily: 'Poppins, sans-serif',
            borderRadius: 10,
            colorPrimary: '#5990D1',
          },
        }}
      >
        <AuthProvider>
          <ArticleProvider>
            <App />
          </ArticleProvider>
        </AuthProvider>
      </ConfigProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
